export function isRestrictedPostalCode(postalCode, twoLetterCountryCode) {
  if (!postalCode || !twoLetterCountryCode) {
    return false;
  }
  let isUkMarket = twoLetterCountryCode.toLowerCase().indexOf('gb') > -1;
  let isFrMarket = twoLetterCountryCode.toLowerCase().indexOf('fr') > -1;
  let isItMarket = twoLetterCountryCode.toLowerCase().indexOf('it') > -1;
  let isEsMarket = twoLetterCountryCode.toLowerCase().indexOf('es') > -1;
  let isAtMarket = twoLetterCountryCode.toLowerCase().indexOf('at') > -1;
  let isFiMarket = twoLetterCountryCode.toLowerCase().indexOf('fi') > -1;
  let isPtMarket = twoLetterCountryCode.toLowerCase().indexOf('pt') > -1;
  let isNoMarket = twoLetterCountryCode.toLowerCase().indexOf('no') > -1;

  postalCode = postalCode.toLowerCase();
  if (isUkMarket && postalCode.length >= 2) {
    let searchstr = postalCode.toLowerCase().substring(0, 2);
    return ['gy', 'je', 'bf', 'im'].indexOf(searchstr) > -1;
  }
  if (isFrMarket && postalCode.length >= 3) {
    let searchstr = postalCode.toLowerCase().substring(0, 3);
    return ['971', '972', '973', '974', '976', '987', '988'].indexOf(searchstr) > -1;
  }
  if (isItMarket && postalCode.length >= 3) {
    let searchstr = postalCode.toLowerCase().substring(0, 5);
    return (
      [
        '47890',
        '47891',
        '47892',
        '47893',
        '47894',
        '47895',
        '47896',
        '47897',
        '47898',
        '47899',
        '00120',
        '23041',
        '22061',
      ].indexOf(searchstr) > -1
    );
  }
  if (isEsMarket && postalCode.length >= 3) {
    if (postalCode.toLowerCase() === 'gx111aa' || postalCode.toLowerCase() === 'gx11 1aa') return true;

    let searchstr = postalCode.toLowerCase().substring(0, 2);

    if (searchstr.startsWith('35') || searchstr.startsWith('38') || searchstr.startsWith('07')) return true;

    searchstr = postalCode.toLowerCase().substring(0, 5);
    return (
      [
        '52001',
        '52002',
        '52003',
        '52004',
        '52005',
        '52006',
        '52070',
        '52071',
        '52080',
        '51001',
        '51002',
        '51003',
        '51004',
        '51005',
        '51070',
        '51071',
        '51080',
        'ad100',
        'ad200',
        'ad300',
        'ad400',
        'ad500',
        'ad600',
        'ad700',
      ].indexOf(searchstr) > -1
    );
  }
  if (isAtMarket && postalCode.length >= 3) {
    let searchstr = postalCode.toLowerCase().substring(0, 4);
    return ['6691', '6991', '6992', '6993'].indexOf(searchstr) > -1;
  }

  if (isFiMarket && postalCode.length >= 2) {
    if (postalCode.startsWith('22')) return true;
  }

  if (isPtMarket && postalCode.length >= 2) {
    let searchstr = postalCode.toLowerCase().substring(0, 2);
    return ['90', '91', '92', '93', '94', '95', '96', '97', '98', '99'].indexOf(searchstr) > -1;
   }

   if (isNoMarket && postalCode.length >= 3) {
     if (postalCode.startsWith('917') || postalCode === '8099') return true;
   }

  return false;
}

export function zipCodeHasErrors(zipCode, countryCode) {
  let hasErrors = true;
  switch (countryCode) {
    case 'US': {
      if (/^\d{5}(?:[-\s]\d{4})?$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'CA': {
      if (/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'DK': {
      if (/^(\d[\-\s]?){4}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'SE': {
      if (/^(\d[\-\s]?){5}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'IT':
    case 'DE': {
      if (/^(\d[\-\s]?){5}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'PL': {
      if (/^\d{2}[-]\d{3}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'NL': {
      if (/^\d{4}\s?[A-Za-z]{2}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'CZ': {
      if (/^\d{3}\s?\d{2}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'FR':
    case 'ES':
    case 'FI': {
      if (/^\d{5}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'GB': {
      if (/^[A-Za-z0-9]{2,4}\s?[A-Za-z0-9]{3}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'BE':
    case 'AT':
    case 'NO': {
      if (/^\d{4}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
    }

    case 'PT': {
      if (/^\d{4}[-]\d{3}$/.test(zipCode)) {
        hasErrors = false;
      }
      break;
      }

    case 'IE': {
        if (/^[A-Za-z0-9]{3}\s?[A-Za-z0-9]{4}$/.test(zipCode)) {
            hasErrors = false;
        }
        break;
    }

    default:
      break;
  }

  return hasErrors;
}

export const displayWarningForMissingAddressNumber = (addressValue, market) => {
  let marketsWithWarning = ['IT', 'CZ', 'PL', 'FI', 'PT', 'IE', 'NO', 'CH'];

  if (!addressValue || !marketsWithWarning.includes(market)) return false;
  return addressValue.match(/\d+/g) == null;
};
